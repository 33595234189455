import { WYSIWYG } from '@components/ui'
import { FC } from 'react'
import cn from 'classnames'
interface props {
  primary: any
  applyWrapper?: boolean
  width_side_menu?: boolean
}

const Content: FC<props> = ({ primary, applyWrapper = true, width_side_menu }) => {
  const { padding_top,
    padding_bottom,
    mobile_padding_top,
    mobile_padding_bottom, } = primary

  return (
    <section className={cn('contentBlockContainer',{ wrapper: applyWrapper, 'grid grid-cols-12': !width_side_menu })}>
      <div
        className={cn('mb-24 contentBlock', {
          'col-span-12 laptop:col-span-6 laptop:col-start-4': !width_side_menu,
        })}
      >
        <WYSIWYG content={primary.content} />
      </div>
      <style jsx global>
        {`
         .contentBlockContainer {
          padding-top: ${padding_top};
          padding-bottom: ${padding_bottom};
        }
        @media (max-width: 768px) {
          .contentBlockContainer {
            padding-top: ${mobile_padding_top};
            padding-bottom: ${mobile_padding_bottom};
          }
        }
          .contentBlock .wysiwyg ul li {
            list-style: disc;
          }
        `}
      </style>
    </section>
  )
}

export default Content
